// Sorted by highest z-index to the lowest one
$zIndexTabHeader:    13372000;
$zIndexScoreOverlay: 13370500;
$zIndexDefault:      13371000;

:export
{
    zIndexTabHeader:    $zIndexTabHeader;
    zIndexScoreOverlay: $zIndexScoreOverlay;
    zIndexDefault:      $zIndexDefault;
}