//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.raceTrack
{
    position: absolute;
    width:    100%;
    height:   100%;
}

:global #nummer
{
    transform-box: fill-box;
    font-family:   'Open Sans', sans-serif;
}

.raceTrackWrapper
{
    width:       100%;
    height:      70%;
    padding-top: 56.4%;
    background:  #7f9cca;
    position:    relative;
}

.goalOverlay
{
    position:        absolute;
    top:             20px;
    left:            20px;
    z-index:         1337;
    border-radius:   3px;
    height:          30px;
    box-sizing:      border-box;
    background:      $colorBlack;
    color:           $colorGray;
    font-size:       16px;
    padding:         0 7px;
    font-weight:     bold;
    display:         flex;
    justify-content: center;
    align-items:     center;

}

.top
{
    height:     70px;
    width:      100%;
    text-align: center;
}

.wrapper
{
    width:    100%;
    height:   100%;
    position: relative;
    overflow: hidden;
}

.zoomControl
{
    position: absolute;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;
    overflow: hidden;
}

.zoomAndPanControlButtons
{
    position:        absolute;
    top:             90px;
    right:           20px;
    z-index:         1337;
    width:           38px;
    border-radius:   3px;
    height:          91px;
    background:      $colorBlack;
    display:         flex;
    align-items:     center;
    flex-direction:  column;
    justify-content: space-around;
}

.zoomAndPanControlButton
{
    cursor:          pointer;
    height:          30px;
    background:      $colorBlack;
    align-items:     center;
    display:         block;
    justify-content: center;
    width:           38px;

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGray;
            }
        }
    }

    &:active
    {
        svg
        {
            *
            {
                fill: $colorYellow;
            }
        }
    }
}

.zoomAndPanControlButtonZoomIn
{
    overflow:      hidden;
    border-radius: 0 0 3px 3px;
}

.zoomAndPanControlButtonZoomOut
{
    overflow:      hidden;
    border-radius: 3px 3px 0 0;
}

.zoomAndPanControlButtonShowNumbers
{
    overflow:      hidden;
    border-radius: 0 0 3px 3px;
}

.zoomAndPanControlButtonShowNumbersActive
{
    svg
    {
        *
        {
            fill: $colorYellow !important;
        }
    }
}

.zoomAndPanControlZoomLevel
{
    text-align: center;
    font-size:  11px;
    position:   absolute;
    bottom:     -18px;
    left:       0;
    width:      38px;
    color:      $colorBlack;
}

.shirtBarWrapper
{
    position: absolute;
    bottom:   0;
    left:     10px;
    z-index:  1337;
}

.shirtBarWrapperDataWrapper
{
    position: absolute;
    top:      0;
    left:     0;

    ul
    {
        list-style:  none;
        white-space: nowrap;

        li
        {
            display:    inline-block;
            color:      $colorWhite;
            width:      212px;
            box-sizing: border-box;
            padding:    34px 0 0 82px;
            font-size:  12px;
        }
    }
}
