//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

$tableChangedAnimationSpeed: 1.5s;

@keyframes tableChangedBlackTranslucent
{
    0%
    {
        background-color: $colorGreen;
        color:            $colorBlack;
    }

    100%
    {
        background: $tableBackgroundColor;
        color:      $colorWhite;
    }
}

@keyframes tableChangedBlackWhite
{
    0%
    {
        background-color: $colorGreen;
        color:            $colorBlack;
    }

    100%
    {
        background-color: $colorWhite;
        color:            $colorBlack;
    }
}

.table
{
    width:          100%;
    max-height:     100%;
    white-space:    nowrap;
    padding-bottom: 50px;

    @media (min-width: $screen-md)
    {
        padding-bottom: 0;
    }

    &.blackTranslucent
    {
        background: $colorBlueDark;
        opacity:    1;
    }

    &.hasPaddingTop
    {
        @media (min-width: $screen-md)
        {
            padding-top: 20px;
        }
    }

    &.white
    {
        background: $colorThemeWhiteWhite;
    }
}

.tableBody
{
    &.tableBodyScroll
    {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display:    block;
    }
}

.tableBodyCellDash
{
    padding: 0;
}

.tableBodyCellDashContent
{
    height: 1px;

    &.tableBodyCellDashContentBlackTranslucent
    {
        background: $tableBackgroundColor;
    }

    &.tableBodyCellDashContentWhite
    {
        background: $colorThemeWhiteGrayLight2;
    }
}

.tableBodyCellText
{
    background-image:    url('../../../assets/images/dash.svg');
    background-repeat:   repeat-x;
    background-position: 0 50%;
    padding:             0 10px 0 10px;
    text-align:          left;

    &.tableBodyCellTextBlackTranslucent
    {
        color: $colorThemeBlackTranslucentWhite;
    }

    &.tableBodyCellTextWhite
    {
        color: $colorThemeWhiteBlack;
    }

    &:first-child
    {
        padding-left: 0;

        .tableBodyCellTextInner
        {
            @media (min-width: $screen-md)
            {
                padding: 0 0 0 26px;
            }
        }
    }

    &:last-child
    {
        padding-right: 0;

        .tableBodyCellTextInner
        {
            @media (min-width: $screen-md)
            {
                padding-right: 25px;
            }
        }
    }
}

.tableBodyCellTextBig
{
    font-size: 20px;
    height:    44px;
}

.tableBodyCellTextFullWidth
{
    padding-right: 0;
    text-align:    left;
}

.tableBodyCellTextFullWidthAfter
{
    padding-left: 0;

    .tableBodyCellTextInner
    {
        padding-left: 25px;
    }
}

.tableBodyCellTextInner
{
    padding: 0 15px;
    display: block;

    &.tableBodyCellTextInnerBlackTranslucent
    {
        background: $tableBackgroundColor;
    }

    &.tableBodyCellTextInnerContentWhite
    {
        background: $colorThemeWhiteWhite;
    }
}

.tableBodyCellTextInnerKeepDash
{
    display: inline-block;
}

.tableBodyCellTextSmall
{
    font-size: 14px;
    height:    30px;
}

.tableBodyRowDash
{

}

.tableBodyRowText
{

}

.tableBodyRowTextChanged
{
    &.tableBodyRowTextBlackTranslucent
    {
        animation: tableChangedBlackTranslucent $tableChangedAnimationSpeed forwards;

        .tableBodyCellTextInner
        {
            animation: tableChangedBlackTranslucent $tableChangedAnimationSpeed forwards;
        }
    }

    &.tableBodyRowTextWhite
    {
        animation: tableChangedWhite $tableChangedAnimationSpeed forwards;

        .tableBodyCellTextInner
        {
            animation: tableChangedWhite $tableChangedAnimationSpeed forwards;
        }
    }
}

.tableBodyRowTextClickable
{
    cursor: pointer;
}

.tableBodyRowTextGroupEnd
{
    td
    {
        padding-bottom: 9px;
    }
}

.tableBodyRowTextGroupStart
{
    td
    {
        padding-top: 9px;
    }
}

.tableBodyRowTextSelected
{
    background: $colorRed;

    .tableBodyCellTextInner
    {
        background: $colorRed;
        color:      $colorWhite;
    }
}

.tableHeader
{
    &.tableHeaderScroll
    {
        display: block;
    }
}

.tableHeaderCell
{
    text-transform: uppercase;
    font-size:      12px;
    height:         30px;
    color:          $colorGray;
    font-weight:    normal;
    letter-spacing: 1.8px;
    text-align:     center;
    user-select:    none;

    &:first-child
    {
        padding:    0 0 0 14px;
        text-align: left;

        @media (min-width: $screen-md)
        {
            padding: 0 0 0 26px;
        }
    }

    &:nth-child(2)
    {
        text-align: left;
        padding:    0 0 0 24px;
    }
}

.tableHeaderCellBig
{

}

.tableHeaderCellSmall
{

}

.tableHeaderCellSmall
{
    cursor: pointer;
}

.wrapper
{
    max-height: 100%;
}
