//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$colorBlack:                          #000000;
$colorBlackTranslucent:               rgba(0, 0, 0, 0.8);
$colorBlue:                           #00321f;
$colorBlueDark:                       #00321f;
$colorBlueLight:                      #0e1d49;
$colorRed:                            #e40813;
$colorGray:                           #999a81;
$colorGreen:                          #005a5a;
$colorWhite60:                        rgba(255, 255, 255, 0.4);
$colorYellow:                         #ffff00;

$tableBackgroundColor:                $colorBlueDark;

// Black translucent
$colorThemeBlackTranslucentBlack:     #2c2f38;
$colorThemeBlackTranslucentBlackDark: #000000;
$colorThemeBlackTranslucentWhite:     #ffffff;

// White
$colorThemeWhiteBlack:                #000000;
$colorThemeWhiteGrayLight2:           #dedede;
$colorThemeWhiteWhite:                #ffffff;

///
/// old
///
///
///
$colorBlack16:                        rgba(0, 0, 0, 0.16);
$colorGrayDark:                       #1f2128;
$colorGrayDark2:                      #343434;
$colorGrayLight:                      #d4d4d4;
$colorGrayLight2:                     #dedede;
$colorWhite:                          #ffffff;

:export
{
    colorBlack:      $colorBlack;
    colorBlack16:    $colorBlack16;
    colorGrayDark:   $colorGrayDark;
    colorGrayDark2:  $colorGrayDark2;
    colorGray:       $colorGray;
    colorGrayLight:  $colorGrayLight;
    colorGrayLight2: $colorGrayLight2;
    colorGreen:      $colorGreen;
    colorRed:        $colorRed;
    colorWhite:      $colorWhite;
}
