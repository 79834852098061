//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';

.wrapper
{
    position: relative;
    display:  inline-block;

    &::after
    {
        width:          0;
        height:         0;
        border-left:    6px solid transparent;
        border-right:   6px solid transparent;
        border-top:     6px solid $colorWhite;
        position:       absolute;
        top:            22px;
        right:          10px;
        content:        '';
        z-index:        98;
        pointer-events: none;
    }

    select
    {
        background:         #005a5a;
        width:              272px;
        height:             50px;
        border:             2px solid #005a5a;
        border-radius:      5px;
        color:              $colorWhite;
        font-size:          20px;
        font-weight:        bold;
        padding:            0 20px;
        outline:            none;
        -moz-appearance:    none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance:         none;
    }
}
