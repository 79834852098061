@import '../../../styles/colors';

.versionNumberWrapper
{
    text-align: right;
    display:    inline;
}

.versionNumber
{
    display: inline;
}