@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

$bodySidePadding: 20px;

.wrapper
{
    position: absolute;
    top:      0;

    h2
    {
        text-transform: uppercase;
        font-size:      12px;
        font-weight:    normal;
        letter-spacing: 1.8px;
        color:          $colorGray;
        margin:         0 0 6px 0;
    }

    strong
    {
        font-size:   26px;
        font-weight: normal;
        color:       $colorWhite;
    }
}

.wrapperLeft
{
    left:       -$bodySidePadding * 4;
    text-align: left;

    @media (min-width: $screen-md)
    {
        left: -$bodySidePadding * 9;
    }

    @media (min-width: $screen-lg)
    {
        left: -$bodySidePadding * 2;
    }

    @media (min-width: 1400px)
    {
        left: $bodySidePadding;
    }
}

.wrapperRight
{
    right:      -$bodySidePadding * 4;
    text-align: right;

    @media (min-width: $screen-md)
    {
        right: -$bodySidePadding * 9;
    }

    @media (min-width: $screen-lg)
    {
        right: -$bodySidePadding * 2;
    }

    @media (min-width: 1400px)
    {
        right: $bodySidePadding;
    }
}