@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.wrapper
{
    $size:          24px;

    width:          $size;
    height:         $size;
    box-sizing:     border-box;
    padding:        0;
    display:        inline-block;
    vertical-align: middle;
    margin:         0 7px 0 0;

    img
    {
        width:      100%;
        height:     100%;
        object-fit: contain;
    }
}