@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.wrapper
{
    position:        absolute;
    bottom:          0;
    left:            0;
    background:      red;
    z-index:         2424;
    width:           100%;
    height:          40px;
    display:         flex;
    justify-content: space-evenly;
    box-sizing:      border-box;
    padding:         3px;

    button
    {
        padding: 0 10px;
    }
}