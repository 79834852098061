@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

$gameSizeHeightReduce:     40%;
$ballAnimationBlur:        1px;
$ballLeftState0Left:       7.7%;
$ballLeftState0Top:        86%;
$ballLeftState1Left:       16.2%;
$ballLeftState1Top:        45.5%;
$ballLeftState2Left:       25.2%;
$ballLeftState2Top:        60.5%;
$ballLeftState3Left:       46.2%;
$ballLeftState3Top:        58%;
$ballLeftState4Left:       69.2%;
$ballLeftState4Top:        39%;
$ballLeftState5Left:       88.2%;
$ballLeftState5Top:        59%;
$ballRightState0Right:     7.7%;
$ballRightState0Top:       6%;
$ballRightState1Right:     15.2%;
$ballRightState1Top:       45.8%;
$ballRightState2Right:     24.2%;
$ballRightState2Top:       60.8%;
$ballRightState3Right:     45.2%;
$ballRightState3Top:       16.8%;
$ballRightState4Right:     68.2%;
$ballRightState4Top:       14.8%;
$ballRightState5Right:     88.2%;
$ballRightState5Top:       34.8%;
$ballSpeed:                0.5s;
$playerKickAnimationSpeed: 0.25s;
$playerHeight:             65px;
$playerWidth:              131px;

.background,
.foreground
{
    background-repeat: no-repeat;
    background-size:   contain;
    height:            100%;
}

.stadion
{
    background-image: url('../../../assets/images/stadion-overlay.png');
    position:         absolute;
    top:              0;
    left:             0;
    width:            100%;
    height:           100%;
}

.background
{
    background-image:    url('../../../assets/images/game-background.svg');
    background-position: center center;
    box-sizing:          border-box;
    text-align:          center;
}

.ball
{
    $size:    38px;
    width:    $size;
    height:   $size;
    position: absolute;

    &.ballTeamLeft
    {
        top:              $ballLeftState0Top;
        left:             $ballLeftState0Left;
        background-image: url('../../../assets/images/ball-left.svg');

        span
        {
            background-color: $colorWhite;
            color:            $colorGrayDark2;
        }
    }

    &.ballTeamRight
    {
        top:              $ballRightState0Top;
        right:            $ballRightState0Right;
        background-image: url('../../../assets/images/ball-right.svg');

        span
        {
            background-color: $colorGrayDark2;
            color:            $colorWhite;
        }
    }

    span
    {
        display:       block;
        width:         $size;
        height:        $size;
        line-height:   $size;
        border-radius: $size / 2;
        font-size:     14px;
        font-weight:   bold;
        opacity:       1;
    }
}

.foreground
{
    background-image:    url('../../../assets/images/game-foreground.svg');
    background-position: center top;
    position:            relative;
    top:                 200px;
    width:               916px;
    height:              400px;
    overflow:            hidden;
    text-align:          center;
    display:             inline-block;
    left:                50%;
    margin:              0 0 0 -100%;

    img
    {
        visibility: hidden;
    }
}

@keyframes playerKick
{
    0%
    {
        background-position-y: 0;
    }

    10%
    {
        background-position-y: -($playerHeight * 1);
    }

    20%
    {
        background-position-y: -($playerHeight * 2);
    }

    30%
    {
        background-position-y: -($playerHeight * 3);
    }

    40%
    {
        background-position-y: -($playerHeight * 4);
    }

    50%
    {
        background-position-y: -($playerHeight * 3);
    }

    60%
    {
        background-position-y: -($playerHeight * 2);
    }

    70%
    {
        background-position-y: -($playerHeight * 1);
    }

    80%
    {
        background-position-y: 0;
    }
}

@for $i from 1 through 12
{
    @keyframes ballOverlay#{$i}
    {
        0%
        {
            opacity: 1;
        }

        1%
        {
            opacity: 0;
        }

        99%
        {
            opacity: 0;
        }

        100%
        {
            opacity: 1;
        }
    }
}

@mixin ballStateChange($direction, $name, $startLeft, $startTop, $stopLeft, $stopTop)
{
    @keyframes #{$name}
    {
        0%
        {
            top:           $startTop;
            #{$direction}: $startLeft;
        }
        1%
        {
            top:           $startTop;
            #{$direction}: $startLeft;
            filter:        blur($ballAnimationBlur);
        }

        99%
        {
            top:           $stopTop;
            #{$direction}: $stopLeft;
            filter:        blur($ballAnimationBlur);
        }

        100%
        {
            top:           $stopTop;
            #{$direction}: $stopLeft;
            filter:        none;
        }
    }
}

@include ballStateChange('left', 'ballLeftState0To1', $ballLeftState0Left, $ballLeftState0Top, $ballLeftState1Left, $ballLeftState1Top);
@include ballStateChange('left', 'ballLeftState1To2', $ballLeftState1Left, $ballLeftState1Top, $ballLeftState2Left, $ballLeftState2Top);
@include ballStateChange('left', 'ballLeftState2To3', $ballLeftState2Left, $ballLeftState2Top, $ballLeftState3Left, $ballLeftState3Top);
@include ballStateChange('left', 'ballLeftState3To4', $ballLeftState3Left, $ballLeftState3Top, $ballLeftState4Left, $ballLeftState4Top);
@include ballStateChange('left', 'ballLeftState4To5', $ballLeftState4Left, $ballLeftState4Top, $ballLeftState5Left, $ballLeftState5Top);
@include ballStateChange('right', 'ballRightState0To1', $ballRightState0Right, $ballRightState0Top, $ballRightState1Right, $ballRightState1Top);
@include ballStateChange('right', 'ballRightState1To2', $ballRightState1Right, $ballRightState1Top, $ballRightState2Right, $ballRightState2Top);
@include ballStateChange('right', 'ballRightState2To3', $ballRightState2Right, $ballRightState2Top, $ballRightState3Right, $ballRightState3Top);
@include ballStateChange('right', 'ballRightState3To4', $ballRightState3Right, $ballRightState3Top, $ballRightState4Right, $ballRightState4Top);
@include ballStateChange('right', 'ballRightState4To5', $ballRightState4Right, $ballRightState4Top, $ballRightState5Right, $ballRightState5Top);

// https://lulububu.atlassian.net/browse/TAURESSALGAM-36

.gameStateTeam1State0
{

}

.gameStateTeam2State0
{

}

.gameStateTeam1State1
{
    .ballTeamLeft
    {
        animation: ballLeftState0To1 $ballSpeed forwards;

        span
        {
            animation: ballOverlay1 $ballSpeed none;
        }
    }
}

.gameStateTeam2State1
{
    .ballTeamRight
    {
        animation: ballRightState0To1 $ballSpeed forwards;

        span
        {
            animation: ballOverlay2 $ballSpeed none;
        }
    }
}

.gameStateTeam1State2
{
    .pole1
    {
        .player
        {
            animation: playerKick $playerKickAnimationSpeed steps(1) forwards;
        }
    }

    .ballTeamLeft
    {
        animation: ballLeftState1To2 $ballSpeed forwards;

        span
        {
            animation: ballOverlay3 $ballSpeed none;
        }
    }
}

.gameStateTeam2State2
{
    .pole8
    {
        .player
        {
            animation: playerKick $playerKickAnimationSpeed steps(1) forwards;
        }
    }

    .ballTeamRight
    {
        animation: ballRightState1To2 $ballSpeed forwards;

        span
        {
            animation: ballOverlay4 $ballSpeed none;
        }
    }
}

.gameStateTeam1State3
{
    .pole2
    {
        .player
        {
            animation: playerKick $playerKickAnimationSpeed steps(1) forwards;
        }
    }

    .ballTeamLeft
    {
        animation: ballLeftState2To3 $ballSpeed forwards;

        span
        {
            animation: ballOverlay5 $ballSpeed none;
        }
    }
}

.gameStateTeam2State3
{
    .pole7
    {
        .player
        {
            animation: playerKick $playerKickAnimationSpeed steps(1) forwards;
        }
    }

    .ballTeamRight
    {
        animation: ballRightState2To3 $ballSpeed forwards;

        span
        {
            animation: ballOverlay6 $ballSpeed none;
        }
    }
}

.gameStateTeam1State4
{
    .pole4
    {
        .player
        {
            animation: playerKick $playerKickAnimationSpeed steps(1) forwards;
        }
    }

    .ballTeamLeft
    {
        animation: ballLeftState3To4 $ballSpeed forwards;

        span
        {
            animation: ballOverlay7 $ballSpeed none;
        }
    }
}

.gameStateTeam2State4
{
    .pole5
    {
        .player
        {
            animation: playerKick $playerKickAnimationSpeed steps(1) forwards;
        }
    }

    .ballTeamRight
    {
        animation: ballRightState3To4 $ballSpeed forwards;

        span
        {
            animation: ballOverlay8 $ballSpeed none;
        }
    }
}

.gameStateTeam1State5
{
    .pole6
    {
        .player
        {
            animation: playerKick $playerKickAnimationSpeed steps(1) forwards;
        }
    }

    .ballTeamLeft
    {
        animation: ballLeftState4To5 $ballSpeed forwards;

        span
        {
            animation: ballOverlay9 $ballSpeed none;
        }
    }
}

.gameStateTeam2State5
{
    .pole3
    {
        .player
        {
            animation: playerKick $playerKickAnimationSpeed steps(1) forwards;
        }
    }

    .ballTeamRight
    {
        animation: ballRightState4To5 $ballSpeed forwards;

        span
        {
            animation: ballOverlay10 $ballSpeed none;
        }
    }
}

.player
{
    width:             $playerWidth;
    height:            $playerHeight;
    position:          absolute;
    background-repeat: no-repeat;

    &.playerLeft
    {
        left:             50%;
        margin:           -32px 0 0 -48px;
        background-image: url('../../../assets/images/player-left-bitmap.png');
    }

    &.playerRight
    {
        right:            50%;
        margin:           -32px -48px 0 0;
        background-image: url('../../../assets/images/player-right-bitmap.png');
    }
}

.pole
{
    position:         absolute;
    background-image: url('../../../assets/images/pole.svg');
    background-size:  cover;
    top:              0;
    height:           100%;
    width:            1%;

    $pole1Left:       14%;
    $pole2Left:       23%;
    $pole3Left:       33%;
    $pole4Left:       44%;

    &.pole1
    {
        left: $pole1Left;
    }

    &.pole2
    {
        left: $pole2Left;
    }

    &.pole3
    {
        left: $pole3Left;
    }

    &.pole4
    {
        left: $pole4Left;
    }

    &.pole5
    {
        left: 100% - $pole4Left;
    }

    &.pole6
    {
        left: 100% - $pole3Left;
    }

    &.pole7
    {
        left: 100% - $pole2Left;
    }

    &.pole8
    {
        left: 100% - $pole1Left;
    }

    &.pole1,
    &.pole8
    {
        .player
        {
            &:nth-child(1)
            {
                top: 50%;
            }
        }
    }

    &.pole2,
    &.pole7
    {
        .player
        {
            &:nth-child(1)
            {
                top: 35%;
            }

            &:nth-child(2)
            {
                top: 65%;
            }
        }
    }

    &.pole4,
    &.pole5
    {
        .player
        {
            &:nth-child(1)
            {
                top: 21%;
            }

            &:nth-child(2)
            {
                top: 42%;
            }

            &:nth-child(3)
            {
                top: 62%;
            }

            &:nth-child(4)
            {
                top: 82%;
            }
        }
    }

    &.pole3,
    &.pole6
    {
        .player
        {
            &:nth-child(1)
            {
                top: 19%;
            }

            &:nth-child(2)
            {
                top: 43%;
            }

            &:nth-child(3)
            {
                top: 66%;
            }
        }
    }
}

.wrapper
{
    position:  relative;
    height:    100%;
    transform: scale(0.75);

    @media (min-width: $screen-md)
    {
        transform: scale(0.6) translateY(-100px);
    }

    @media (min-width: $screen-lg)
    {
        transform: scale(0.9);
    }

    @media (min-width: 1400px)
    {
        transform: scale(1);
    }
}