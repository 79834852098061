//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import 'styles/breakpoints';
@import 'styles/fonts';
@import 'styles/colors';

$bodySidePadding: 29px;

*
{
    margin:  0;
    padding: 0;
}

body,
html
{
    height: 100%;
}

:global #root
{
    min-height: 100%;
}

body
{
    padding:                 0;
    margin:                  0;
    font-family:             $fontInter;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image:        url('./assets/images/bundesliga/background-desktop.jpg');
    background-repeat:       no-repeat;
    background-position:     top center;
    background-size:         cover;
    background-color:        #00cf7f;

    @media (min-width: $screen-md)
    {
        padding:             0 $bodySidePadding 0 $bodySidePadding;
        background-image:    url('./assets/images/bundesliga/background-desktop.jpg');
        background-repeat:   no-repeat;
        background-position: center center;
        background-size:     cover;
        overflow:            hidden;
    }
}

.appContent
{
    padding:    0 0 $bodySidePadding 0;
    margin:     0;
    min-height: 100%;
}

.mobileHeaderWrapper
{
    img
    {
        width: 100%;

        @media (min-width: $screen-md)
        {
            display: none;
        }
    }
}

$heightRow1-sm:   300px;
$heightRow2-sm:   300px;
$heightRow1-md:   40vh;
$heightRow2-md:   30vh;
$heightRow1:      60vh;
$heightRow2:      30vh;
$gapSize:         41px;
$widthColumn1-sm: 100%;
$widthColumn2-sm: 100%;
$widthColumn1-md: 40%;
$widthColumn2-md: 100% - $widthColumn1-md;
$widthColumn1:    30%;
$widthColumn2:    100% - $widthColumn1;

.homeContent
{
    display:         flex;
    flex-wrap:       wrap;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;
}

.homeCurrentRace
{
    box-sizing: border-box;
    padding:    0;
    height:     $heightRow1-sm;
    width:      $widthColumn1-sm;
    margin:     0;

    @media (min-width: $screen-md)
    {
        height:  $heightRow1-md;
        width:   $widthColumn1-md;
        margin:  0;
        padding: 0 0 $gapSize 0;
    }

    @media (min-width: $screen-lg)
    {
        height: $heightRow1;
        width:  $widthColumn1;
        margin: 0;
    }
}

.text
{
    font-size:       36px;
    color:           $colorWhite;
    font-weight:     normal;
    flex:            1;
    align-items:     center;
    justify-content: center;
    display:         flex;
    font-family:     $fontInter;
    text-shadow:     0 2px 4px rgba(0, 0, 0, 0.5);

    &.textLeft
    {
        margin: 0 25px 0 0;
    }

    &.textRight
    {
        margin: 0 0 0 25px;
    }
}

.firstRow
{
    display: flex;
}

.homeVisual
{
    box-sizing: border-box;
    padding:    0;
    height:     600px;
    overflow:   hidden;
    position:   relative;
    width:      $widthColumn2-sm;

    @media (min-width: $screen-md)
    {
        height:  $heightRow1-md;
        width:   $widthColumn2-md;
        padding: 0 0 $gapSize $gapSize;
    }

    @media (min-width: $screen-lg)
    {
        padding: 0 0 $gapSize $gapSize;
        height:  $heightRow1;
        width:   $widthColumn2;
    }
}

.homeFlashTable,
.homeTabTable
{
    width:  100%;
    margin: 0 0 50px 0;

    &.homeTabTable
    {
        height: 300px;
    }

    &.homeFlashTable
    {
        margin-top: 50px;
    }

    @media (min-width: $screen-md)
    {
        height: 600px;
        width:  90%;
    }

    @media (min-width: $screen-lg)
    {
        height: 600px;
        width:  1000px;
    }
}
