//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.wrapper
{
    text-transform: uppercase;
    font-size:      12px;
    top:            13px;
    position:       absolute;
    right:          0;
    color:          $colorWhite;
    letter-spacing: 1.8px;

    .dayWrapper
    {
        margin-right: 26px;
    }

    strong
    {
        font-size:      20px;
        font-weight:    500;
        text-transform: none;
        margin-right:   5px;
        letter-spacing: 1px;
    }
}
