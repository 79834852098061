//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

$borderRadius:    17px;
$height:          33px;
$skewX:           -12deg;
$transitionCurve: ease;
$transitionSpeed: 0.1s;

.contentWrapper
{
    padding:    0 10px 0 0;
    background: $colorBlue;
}

.headline
{

}

.tab
{
    height:      100%;
    font-size:   20px;
    text-align:  center;
    line-height: $height;
    font-weight: 500;
    position:    relative;
    cursor:      pointer;
    transition:  color $transitionSpeed $transitionCurve;

    &.tabDiagonal
    {
        transform: skew(-$skewX, 0deg);

        &.tabBlue
        {
            color: $colorWhite;
        }

        &.tabRed
        {
            color: $colorWhite;
        }

        &.tabWhite
        {
            color: $colorGreen;
        }
    }

    &.tabRound
    {
        &.tabBlack
        {
            color: $colorBlack;
        }

        &.tabRed
        {

        }

        &.tabWhite
        {
            color: $colorGreen;
        }
    }
}

.tabNotClickable
{
    cursor: default;
}

.tabActive
{
    color: $colorBlack;
}

.tabUnderlay
{
    position:   absolute;
    height:     100%;
    transition: left $transitionSpeed $transitionCurve;

    &.tabUnderlayDiagonal
    {

        &.tabUnderlayBlue
        {
            background: $colorBlueLight;
            filter:     drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.3));
        }

        &.tabUnderlayRed
        {
            background: $colorRed;
            filter:     drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.3));
        }

        &.tabUnderlayWhite
        {
            background: $colorWhite;
            filter:     drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.3));
        }
    }

    &.tabUnderlayRound
    {
        border-radius: $borderRadius;

        &.tabUnderlayBlack
        {
            background: $tableBackgroundColor;
        }

        &.tabUnderlayRed
        {

        }

        &.tabUnderlayWhite
        {
            color: $colorGreen;
        }
    }
}

.tabs
{
    position:    absolute;
    top:         -10px;
    display:     flex;
    height:      $height;
    box-shadow:  0 3px 6px 0 $colorBlack16;
    user-select: none;
    z-index:     $zIndexTabHeader;

    @media (min-width: $screen-md)
    {
        top: -20px;
    }

    &.tabsDiagonal
    {
        left:      20px;
        transform: skew($skewX, 0deg);

        &.tabsBlack
        {

        }

        &.tabsBlue
        {

        }

        &.tabsRed
        {
            background: $colorBlack;
        }

        &.tabsWhite
        {
            background: $colorGray;
        }
    }

    &.tabsRound
    {
        overflow:      hidden;
        padding:       0;
        left:          10px;
        border-width:  1px;
        border-style:  solid;
        border-radius: $borderRadius;

        &.tabsBlack
        {
            background:   $colorWhite;
            border-color: $colorGrayLight;
        }

        &.tabsRed
        {

        }

        &.tabsWhite
        {

        }
    }
}

.wrapper
{
    border-radius:    5px;
    box-shadow:       0 3px 6px 0 $colorBlack16;
    background-color: transparent;
    position:         relative;
    padding:          20px 0 0 0;

    @media (min-width: $screen-md)
    {
        width:    100%;
        height:   100%;
        position: relative;
        padding:  0;
    }
}
