//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.wrapper
{

}

.container
{
    height:        60px;
    background:    $colorBlackTranslucent;
    border-radius: 0 0 5px 5px;
    box-shadow:    0 3px 6px 0 $colorBlack16;
    display:       flex;

    *
    {
        transition: color 0.1s linear;
    }

    &.containerHighlighted
    {
        background: $colorWhite;
        color:      $colorBlue;

        *
        {
            color: $colorBlue;
        }
    }
}

.left
{
    padding:     0 0 0 20px;
    flex:        1;
    align-items: center;
    text-align:  left;
    line-height: 15px;
    color:       $colorWhite;
    font-size:   16px;
    font-weight: 500;
    display:     none;

    @media (min-width: $screen-md)
    {
        display: flex;
    }
}

.center
{
    flex: 1;
}

.headerBadge
{
    background-image:    url('../../../assets/images/bundesliga/header-badge.svg');
    background-size:     contain;
    background-position: center;
    width:               385px;
    padding:             17px 0 5px 0;
    box-sizing:          border-box;
    height:              77px;
    text-align:          center;
    font-size:           24px;
    font-weight:         900;
    text-transform:      uppercase;
    color:               $colorWhite;
    margin:              0 auto;
    display:             flex;
    align-items:         baseline;
    justify-content:     center;
    filter:              drop-shadow(1px 6px 14px rgba(0, 0, 0, 0.2));

    svg
    {
        height: 82%;
    }

    img
    {
        width: 64%;
    }
}

.right
{
    flex:            1;
    align-items:     center;
    text-align:      right;
    line-height:     15px;
    padding:         0 20px 0 0;
    color:           $colorBlack;
    opacity:         0;
    transition:      opacity 0.25s linear;
    font-size:       10px;
    justify-content: flex-end;
    display:         none;

    @media (min-width: $screen-md)
    {
        display: flex;
    }

    &:hover
    {
        opacity: 1;
    }
}
